.versets_wrapper {
	position: relative;
}

.verset_inner {
	position: relative;
	z-index: 2;
}

#versets_canvas {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.verset_inner .number {
	bottom: 0;
	top: 0;
	font-weight: 700;
	padding-right: 4px;
	margin-right: 2px;
	left: -2.4em;
	position: absolute;
	text-align: right;
	min-width: 3em;
}

.verset_inner .verset {
	padding-left: 1em;
}

.verset_inner .verset .bible_letter {
	position: relative;
}

.verset_inner .verset .bible_letter.lightfont {
	color: #fff;
}

.verset_inner .verset .bible_letter a.theme {
	background-color: hsla(0, 0%, 100%, 0.8);
	border-radius: 1px;
	font-size: 9px;
	font-weight: 700;
	font-family: Verdana, sans-serif;
	left: -2px;
	line-height: 9px;
	padding: 1px;
	position: absolute;
	text-decoration: none;
	top: -8px;
}

.pagination {
	background-color: #eee;
	display: flex;
	flex: auto;
	flex-direction: row;
	flex-wrap: wrap;
}

a.chapitre {
	display: inline-block;
	height: 2em;
	line-height: 2em;
	padding: 2px;
	text-align: center;
	width: 2em;
}

span.title {
	padding-left: 1em;
	padding-right: 1em;
}

.curseur_wrapper .curseur_inner {
	background-color: red;
	padding: 2px;
}

.number.selected {
	background-color: lightgray;
	border-radius: 6px;
}

#versets_canvas {
    position: absolute;
    top: 0;
    left: 0;
}